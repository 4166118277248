import React from "react";
import logo from "../../assets/img/new-font-logo.svg";

const HideWeb = () => {
  return (
    <div className="hideWeb">
      <img src={logo} alt="Todos logo" />
      <h2>Skift til mobilen for nu {":-)"}</h2>
    </div>
  );
};
export default HideWeb;
